import { useCategoryStore } from "@/stores/category";
import CategoriesServices from "@/services/Category";
import ConfigurationService from "@/services/Configuration.js"

export default async function createCustomerCategory(parent_category) {

	let categories = useCategoryStore()
	var related_entity = 'customers'

	// Get attributes list
	let attr_list = await CategoriesServices.getAttributesList(
		-1,
		related_entity
	);
	// Assign the attribute to categories
	categories.attributes = attr_list.map((d) => {
		d.id = d.guid;
		d.label = d.name;
		return d;
	});
	// Get inherited attributes list
	var parent_attr_list = await CategoriesServices.getParentAttributeList(
		parent_category,
		related_entity,
		1
	);
	// Assign to category 
	categories.inherited_attributes = parent_attr_list.map((d) => {
		d.id = d.guid;
		d.label = d.name;
		return d;
	});

	// Get payment terms
	var payments_data = await ConfigurationService.getPaymentsConfiguration()
	var payment_terms = payments_data.payment_terms.map(a => {
		a.id = a.guid
		a.label = a.name
		return a
	})

	// form: (c, related_entity) => { 
	var ob = {} 
	ob = {
		name: "Krijo" + (categories.current.guid ? " Sub" : "") 
			+" Kategori" + (categories.current.name ? ": <i>" + categories.current.name +"</i>" : ''),
		submit: {
			label: 'Krijo', 
			action: 'submitCategory'
		},
		fields: [
			[
				{ 
					value: '', 
					name: 'code', 
					label: 'Kodi', 
					type: 'text-field', 
					unique: {
						model: 'category', 
						field: 'code',
						where: [
							{
								column: 'related_entity',
								condition: '=',
								value: related_entity 
							}
						]
					}, 
					left_icon: 'fa-solid fa-hashtag', 
					style: "min-width: 45%",
					required: true,
				},
				{
					value: '', 
					name: 'name', 
					label: 'Emri', 
					type: 'text-field', 
					style: 'min-width: 45%',
					required: true,
					required_text: 'Kjo fushe eshte e detyrueshme'
				},
				{
					value: 0, 
					name: 'active', 
					label: 'Aktiv', 
					style: "min-width: 2%",
					type: 'checkbox-field', 
				},
			],
			{ 
				value: '', 
				name: 'description', 
				label: 'Pershkrim', 
				type: 'textarea-field',
			},
			{
				name: 'categories_attributes', 
				label: 'Atributet', 
				type: 'tree-select', 
				style: 'width: 100%',
				options: categories.attributes.filter(a => {
					var found = false
					categories.inherited_attributes.map(aa => {
						if(aa.attribute_id == a.id) found = true
					})
					return !found
				}), 
				selected_options: [],
				inherited_selected_options: categories.inherited_attributes,

				mode: 'multiple',
				required: false
			},

			{
				name: 'payment_terms', 
				label: 'Termat e pageses', 
				type: 'tree-select', 
				style: 'width: 100%',
				options: payment_terms, 
				selected_options: [],
				inherited_selected_options: [],

				mode: 'multiple',
				required: false
			},
		]
	} 
	// }
	return ob
}
