import { defineStore } from 'pinia'

export const useAccessoryStore = defineStore({
  id: 'accessories',
  state: () => ({
	list: [], 
    flat_list: [],
    current: '',
    mode: '',
  }),
  getters: {
  }, 
  actions: {
  }
})
