import { useCategoryStore } from "@/stores/category";
import CategoriesServices from "@/services/Category";
// import helpers from "@/helpers.js";

export default async function createAccessoryCategory(parent_category) {
	let categories = useCategoryStore()
	var related_entity = 'accessories'

	// Get attributes list
	let attr_list = await CategoriesServices.getAttributesList(
		-1,
		'products'
	);
	// Assign the attribute to categories
	categories.attributes = attr_list.map((d) => {
		d.id = d.guid;
		d.label = d.name;
		return d;
	});

	// Get inherited attributes list
	var parent_attr_list = await CategoriesServices.getParentAttributeList(
		parent_category,
		related_entity,
		1
	);

	// Assign to category 
	categories.inherited_attributes = parent_attr_list.map((d) => {
		d.id = d.guid;
		d.label = d.name;
		return d;
	});
	
	// Get services categories
	let service_types = await CategoriesServices.getServicesCategories()
	categories.services = service_types.map((d) => {
		d.id = d.guid;
		d.label = d.name;
		return d;
	});
	
	// Get inherited service_type list
	var parent_service_type_list = await CategoriesServices.getParentServiceTypeList(
		parent_category,
		1
	);
	categories.inherited_service_types = parent_service_type_list.map((d) => {
		d.id = d.guid;
		d.label = d.name;
		return d;
	});

	var ob = {} 
	ob = {
		name: "Krijo" + (categories.current.guid ? " Sub" : "") 
			+" Aksesor" + (categories.current.name ? ": <i>" + categories.current.name +"</i>" : ''),
		submit: {
			label: 'Krijo', 
			action: 'submitAccessory'
		},
		fields: [
			[
				{ 
					value: '', 
					name: 'code', 
					label: 'Kodi', 
					type: 'text-field', 
					unique: {
						model: 'accessory', 
						field: 'code',
						where: [
							{
								column: 'related_entity',
								condition: '=',
								value: related_entity 
							}
						]
					}, 
					left_icon: 'fa-solid fa-hashtag', 
					style: "min-width: 45%",
					required: true,
				},
				{
					value: '', 
					name: 'name', 
					label: 'Emri', 
					type: 'text-field', 
					style: "min-width: 45%",
					required: true,
					required_text: 'Kjo fushe eshte e detyrueshme'
				},
				{
					value: 0, 
					name: 'active', 
					label: 'Aktiv', 
					style: "min-width: 2%",
					type: 'checkbox-field', 
				},
			],
			{ 
				value: '', 
				name: 'description', 
				label: 'Pershkrim', 
				type: 'textarea-field',
				required: false
			},
			{
				name: 'categories_attributes', 
				label: 'Atributet', 
				type: 'tree-select', 
				style: 'width: 100%',
				options: categories.attributes.filter(a => {
					var found = false
					categories.inherited_attributes.map(aa => {
						if(aa.attribute_id == a.id) found = true
					})
					return !found
				}), 
				selected_options: [],
				inherited_selected_options: categories.inherited_attributes,

				mode: 'multiple',
				required: false
			},
			{
				name: 'categories_services', 
				label: 'Tipet e sherbimeve', 
				type: 'tree-select', 
				style: 'width: 100%',
				// options: categories.services, 
				options: categories.services.filter(a => {
					var found = false
					categories.inherited_service_types.map(aa => {
						if(aa.service_type_id == a.id) found = true
					})
					return !found
				}), 
				selected_options: [],
				inherited_selected_options: categories.inherited_service_types,
				mode: 'multiple',
				required: false
			}
		]
	} 
	return ob
}
