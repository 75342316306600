import { useCategoryStore } from "@/stores/category";

export default async function createWarehouseCategory() {

	let categories = useCategoryStore()
	var related_entity = 'warehouses'

	// form: (c, related_entity) => { 
	var ob = {} 
	ob = {
		name: "Krijo" + (categories.current.guid ? " Sub" : "") 
			+" Kategori" + (categories.current.name ? ": <i>" + categories.current.name +"</i>" : ''),
		submit: {
			label: 'Krijo', 
			action: 'submitCategory'
		},
		fields: [
			[
				{ 
					value: '', 
					name: 'code', 
					label: 'Kodi', 
					type: 'text-field', 
					unique: {
						model: 'category', 
						field: 'code',
						where: [
							{
								column: 'related_entity',
								condition: '=',
								value: related_entity 
							}
						]
					}, 
					left_icon: 'fa-solid fa-hashtag',
					style: "min-width: 45%", 
					required: true,
				},
				{
					value: '', 
					name: 'name', 
					label: 'Emri', 
					type: 'text-field', 
					style: "min-width: 45%",
					required: true,
					required_text: 'Kjo fushe eshte e detyrueshme'
				},
				{
					value: 0, 
					name: 'active', 
					label: 'Aktiv', 
					style: "min-width: 2%",
					type: 'checkbox-field', 
				},
			],
			{ 
				value: '', 
				name: 'description', 
				label: 'Pershkrim', 
				type: 'textarea-field',
			}
		]
	} 
	return ob
}
