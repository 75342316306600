import { useCategoryStore } from "@/stores/category";
import helpers from "@/helpers.js";
import CategoriesServices from "@/services/Category";
import Api from "@/services/Api";

export default async function createCategory(parent_category) {

	let categories = useCategoryStore()
	var related_entity = 'products'

	// Get attributes list
	let attr_list = await CategoriesServices.getAttributesList(
		-1,
		related_entity
	);
	// Assign the attribute to categories
	categories.attributes = attr_list.map((d) => {
		d.id = d.guid;
		d.label = d.name;
		return d;
	});
	// Get inherited attributes list
	var parent_attr_list = await CategoriesServices.getParentAttributeList(
		parent_category,
		related_entity,
		1
	);
	// Assign to category 
	categories.inherited_attributes = parent_attr_list.map((d) => {
		d.id = d.guid;
		d.label = d.name;
		return d;
	});

	// Get accessories list
	let accessories_list = await CategoriesServices.getCategories("accessories")
	// Assign the accessories to categories
	categories.accessories = helpers.toTree(accessories_list, "id")

	var parent_accessories_list = await CategoriesServices.getParentAccessoryList(
		parent_category,
		related_entity,
		1
	);
	categories.inherited_accessories = parent_accessories_list.map((d) => {
		d.id = d.guid;
		d.label = d.name;
		return d;
	});

	// Get services categories
	let service_types = await CategoriesServices.getServicesCategories()
	categories.services = service_types.map((d) => {
		d.id = d.guid;
		d.label = d.name;
		return d;
	});
	
	// Get inherited service_type list
	var parent_service_type_list = await CategoriesServices.getParentServiceTypeList(
		parent_category,
		1
	);
	categories.inherited_service_types = parent_service_type_list.map((d) => {
		d.id = d.guid;
		d.label = d.name;
		return d;
	});

	// Get users list for manager field
	var managers_list = []
	var roles_list = {}
	await Api(true).get('/user/get/users')
	.then(res => {
		managers_list = [...res.data.map(a => {
			a.label = a.name
			if(!roles_list[a.type_name])
				roles_list[a.type_name] = []
			roles_list[a.type_name].push(a)
			return a
			// return { ...a, label: a.name }
		})]
	})


	var ob = {} 
	ob = {
		name: "Krijo" + (categories.current.guid ? " Sub" : "") 
			+" Kategori" + (categories.current.name ? ": <i>" + categories.current.name +"</i>" : ''),
		submit: {
			label: 'Krijo', 
			action: 'submitCategory'
		},
		fields: [
			[
				{ 
					value: '', 
					name: 'code', 
					label: 'Kodi', 
					type: 'text-field', 
					unique: {
						model: 'category', 
						field: 'code',
						where: [
							{
								column: 'related_entity',
								condition: '=',
								value: related_entity 
							}
						]
					}, 
					left_icon: 'fa-solid fa-hashtag', 
					style: "min-width: 45%",
					required: true,
				},
				{
					value: '', 
					name: 'name', 
					label: 'Emri', 
					type: 'text-field', 
					style: 'flex: 1',
					required: true,
					required_text: 'Kjo fushe eshte e detyrueshme'
				},
				{
					value: 0, 
					name: 'active', 
					label: 'Aktiv', 
					type: 'checkbox-field', 
					style: "min-width: 80px"
				},
			],
			[
				{
					value: '', 
					name: 'universal_name', 
					label: 'Emri Universal', 
					type: 'text-field', 
					style: "min-width: 45%",
					required: true,
					required_text: 'Kjo fushe eshte e detyrueshme'
				},
				{
					value: '', 
					name: 'manager', 
					label: 'Menaxheri i kategorise', 
					type: 'select-field', 
					style: 'flex: 1',
					options: managers_list,
					options_group: 'type_id',
					options_group_label: 'type_name',
					// required: true,
					required_text: 'Kjo fushe eshte e detyrueshme'
				}
			],
			[
				{
					value: 0,
					name: "is_included_at_product_code",
					label: "Perfshihet ne kodin e produktit",
          style: "max-width: 20%",
					type: "checkbox-field",
				},
				{
					value: 0,
					name: "is_included_at_pr_name",
					label: "Perfshihet ne emrin e produktit",
          style: "max-width: 20%",
					type: "checkbox-field",
				},
				{
					value: 0,
					name: "is_included_at_description",
					label: "Perfshihet ne pershkrim",
          style: "max-width: 20%",
					type: "checkbox-field",
				},
				{
					value: 0,
					name: "is_shown_in_web",
					label: "Shfaqet ne web",
          style: "max-width: 20%",
					type: "checkbox-field",
				},
			],
			{ 
				value: '', 
				name: 'description', 
				label: 'Pershkrim', 
				type: 'textarea-field',
			},
			{
				name: 'categories_attributes', 
				label: 'Atributet', 
				type: 'tree-select', 
				style: 'width: 100%',
				options: categories.attributes.filter(a => {
					var found = false
					categories.inherited_attributes.map(aa => {
						if(aa.attribute_id == a.id) found = true
					})
					return !found
				}), 
				selected_options: [],
				inherited_selected_options: categories.inherited_attributes,

				mode: 'multiple',
				required: false
			},
			{
				name: "categories_accessories",
				label: "Aksesoret",
				type: "tree-select",
				style: "min-width: 30%",
				options: categories.accessories.filter(a => {
					var found = false
					categories.inherited_accessories.map(aa => {
						if(aa.accessory_id == a.id) found = true
					})
					return !found
				}), 
				selected_options: [],
				inherited_selected_options: categories.inherited_accessories,

				mode: 'multiple',
				
			},
			{
				name: 'categories_services', 
				label: 'Tipet e sherbimeve', 
				type: 'tree-select', 
				style: 'width: 100%',
				options: categories.services.filter(a => {
					var found = false
					categories.inherited_service_types.map(aa => {
						if(aa.service_type_id == a.id) found = true
					})
					return !found
				}), 
				selected_options: [],
				inherited_selected_options: categories.inherited_service_types,
				mode: 'multiple',
				required: false
			},
		]
	} 
	return ob
}
