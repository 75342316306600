import { useCategoryStore } from "@/stores/category";
import helpers from "@/helpers.js";
import CategoriesServices from "@/services/Category";
import Api from "@/services/Api";

export default async function editCategory(parent_category) {
  // form: (c, related_entity ) => {

  let categories = useCategoryStore()
  var related_entity = 'products'

  let attr_list = await CategoriesServices.getAttributesList(
    -1,
    related_entity
  );


  categories.attributes = attr_list.map((d) => {
    d.id = d.guid;
    d.label = d.name;
    return d;
  });
  var parent_attr_list = await CategoriesServices.getParentAttributeList(
    parent_category,
    related_entity,
    0
  );
  categories.inherited_attributes = parent_attr_list.map((d) => {
    d.id = d.guid;
    d.label = d.name;
    return d;
  });

  let accessories_list = await CategoriesServices.getCategories("accessories")
  // Assign the accessories to categories
  categories.accessories = helpers.toTree(accessories_list, "id")

  var parent_acc_list = await CategoriesServices.getParentAccessoryList(
    parent_category,
    related_entity,
    0
  );
  categories.inherited_accessories = parent_acc_list.map((d) => {
    d.id = d.guid;
    d.label = d.name;
    return d;
  });

  let selected_category = {};
  selected_category[categories.current.guid] = true;

  categories.categories_attributes = await CategoriesServices.getParentAttributeList(
    parent_category,
    related_entity,
    1
  );


  /* Selected options group */
  var selected_options = {}
  if (categories.categories_attributes)
    categories.categories_attributes.map(ca => {
      //if(ca.category_id == categories.current.guid)

      // check is not already in the inherited list 
      var found = false
      categories.inherited_attributes.map(ia => {
        if (ia.attribute_id == ca.attribute_id || ia.guid == ca.attribute_id) found = true
      })
      if (!found)
        selected_options[ca.attribute_id] = ca.attribute_name
    })

  let entity_accessories = await CategoriesServices.getEntityAttributes(
    'accessories'
  );
  categories.categories_accessories = entity_accessories.map((d) => {
    d.id = d.guid;
    d.label = d.name;
    return d;
  });

  var selected_options_accessories = {}
  if (categories.categories_accessories)
    categories.categories_accessories.map(ca => {
      if (ca.product_category_id == categories.current.guid)
        selected_options_accessories[ca.accessory_category_id] = ca.name
    })

  var selected_options_service_type = {}
  if (categories.categories_service_types)
    categories.categories_service_types.map(ca => {
      if (ca.category_id == categories.current.guid)
        selected_options_service_type[ca.service_type_id] = ca.name
    })


  // Get services categories
  let service_types = await CategoriesServices.getServicesCategories()
  categories.services = service_types.map((d) => {
    d.id = d.guid;
    d.label = d.name;
    return d;
  });

  // Get inherited service_type list
  var parent_service_type_list = await CategoriesServices.getParentServiceTypeList(
    parent_category,
    0
  );
  categories.inherited_service_types = parent_service_type_list.map((d) => {
    d.id = d.guid;
    d.label = d.name;
    return d;
  });

  // Get users list for manager field
  var managers_list = []
  await Api(true).get('/user/get/users')
    .then(res => {
      managers_list = [...res.data.map(a => {
        a.label = a.name

        return a
      })]
    })

  // Transfer category
  var is_transfer = false

  var product_categories = [...categories?.flat_list.filter(a => {
    return a.guid != categories.current.guid
  })]

  // await CategoriesServices.getCategories("products");
  var details_types = helpers.toTree(product_categories, "id")

  // create sort list 
  var sort_options = []
  categories.flat_list.map(f => {
    if (f.parent_id == categories.current.parent_id) {
      sort_options.push({
        id: f.sort_nr,
        label: f.sort_nr
      })
    }
  })

  // sort sort_options by label 
  sort_options.sort((a, b) => {
    return a.label - b.label
  })

  var transfer_category = []
  if (categories.current?.total >= 0) {
    transfer_category = [
      {
        value: is_transfer,
        name: "is_transfer",
        label: "Transfero ne kategorine",
        style: "min-width: 10%",
        type: "checkbox-field",
      },
      {
        name: "transfer_parent_id",
        label: "Kategoria",
        type: "tree-select",
        style: "min-width: 90%",
        options: details_types,
        mode: "single",
        select_root: true,
        selected_options: [],
        vif: [{ field: "is_transfer", value_not: 0 }],
      },
    ]
  }

  return {
    name: "<span class='mr-1'>Modifiko</span> <b>" + categories.current.name + "</b>",
    submit: {
      label: 'Modifiko',
      action: 'submitCategory'
    },
    delete: {
      guid: categories.current.guid,
      model: 'Category',
      api: 'category/delete/' + related_entity
    },
    fields: [
      [
        {
          value: categories.current.code,
          name: 'code',
          label: 'Kodi',
          type: 'text-field',
          unique: {
            model: 'category',
            field: 'code',
            where: [
              {
                column: 'related_entity',
                condition: '=',
                value: related_entity
              },
              {
                column: 'guid',
                condition: '!=',
                value: categories.current.guid
              }
            ]
          },
          left_icon: 'fa-solid fa-hashtag',
          style: "min-width: 45%",
          required: true,
        },
        {
          value: categories.current.name,
          name: 'name',
          label: 'Emri',
          type: 'text-field',
          style: 'flex: 1',
          required: true,
          required_text: 'Kjo fushe eshte e detyrueshme'
        },
        {
          value: categories.current.active,
          name: 'active',
          label: 'Aktiv',
          type: 'checkbox-field',
          style: "min-width: 80px",
        },
        {
          value: categories.current.sort_nr,
          name: 'sort_nr',
          label: 'Renditja',
          type: 'select-field',
          options: sort_options,
          style: "",
        },
      ],
      [
        {
          value: categories.current.universal_name,
          name: 'universal_name',
          label: 'Emri Universal',
          type: 'text-field',
          style: 'min-width: 45%',
          required: true,
          required_text: 'Kjo fushe eshte e detyrueshme'
        },
        {
          value: categories.current.manager,
          name: 'manager',
          label: 'Menaxheri i kategorise',
          type: 'select-field',
          style: 'flex: 1',
          options: managers_list,
          options_group: 'type_id',
          options_group_label: 'type_name',
          // required: true,
          required_text: 'Kjo fushe eshte e detyrueshme'
        }
        // {
        // 	value: categories.current.manager, 
        // 	name: 'manager', 
        // 	label: 'Menaxheri i kategorise', 
        // 	type: 'text-field', 
        // 	style: 'min-width: 45%',
        // 	required: true,
        // 	required_text: 'Kjo fushe eshte e detyrueshme'
        // }
      ],
      [
        {
          value: categories.current.is_included_at_product_code,
          name: "is_included_at_product_code",
          label: "Perfshihet ne kodin e produktit",
          style: "max-width: 20%",
          type: "checkbox-field",
        },
        {
          value: categories.current.is_included_at_pr_name,
          name: "is_included_at_pr_name",
          label: "Perfshihet ne emrin e produktit",
          style: "max-width: 20%",
          type: "checkbox-field",
        },
        {
          value: categories.current.is_included_at_description,
          name: "is_included_at_description",
          label: "Perfshihet ne pershkrim",
          style: "max-width: 20%",
          type: "checkbox-field",
        },
        {
          value: categories.current.is_shown_in_web,
          name: "is_shown_in_web",
          label: "Shfaqet ne web",
          style: "max-width: 20%",
          type: "checkbox-field",
        },
      ],
      {
        value: categories.current.description,
        name: 'description',
        label: 'Pershkrim',
        type: 'textarea-field',
      },
      {
        name: 'categories_attributes',
        label: 'Atributet',
        type: 'tree-select',
        style: 'width: 100%',
        options: categories.attributes.filter(a => {
          var found = false
          categories.inherited_attributes.map(aa => {
            if (aa.attribute_id == a.id) found = true
          })
          return !found
        }),
        selected_options: selected_options,
        inherited_selected_options: categories.inherited_attributes,
        mode: 'multiple',
        required: false
      },
      {
        name: "categories_accessories",
        label: "Aksesoret",
        type: "tree-select",
        style: "min-width: 30%",
        // options: accessories,
        // selected_options: [],

        options: categories.accessories.filter(a => {
          var found = false
          categories.inherited_accessories.map(aa => {
            if (aa.accessory_id == a.id) found = true
          })
          return !found
        }),
        selected_options: selected_options_accessories,
        inherited_selected_options: categories.inherited_accessories,

        mode: 'multiple',
      },
      {
        name: 'categories_services',
        label: 'Tipet e sherbimeve',
        type: 'tree-select',
        style: 'width: 100%',
        // options: categories.services, 
        options: categories.services.filter(a => {
          var found = false
          categories.inherited_service_types.map(aa => {
            if (aa.service_type_id == a.id) found = true
          })
          return !found
        }),
        selected_options: selected_options_service_type,
        inherited_selected_options: categories.inherited_service_types,
        mode: 'multiple',
        required: false
      },
      [...transfer_category]
    ],
  }
}
