import { useCategoryStore } from "@/stores/category";
import CategoriesServices from "@/services/Category";

export default async function editEmployeeCategory(parent_category) {

	let categories = useCategoryStore()
	var related_entity = 'employees'

	let attr_list = await CategoriesServices.getAttributesList(
		-1,
		related_entity
	);

	categories.attributes = attr_list.map((d) => {
		d.id = d.guid;
		d.label = d.name;
		return d;
	});

	var parent_attr_list = await CategoriesServices.getParentAttributeList(
		parent_category,
		related_entity,
		0
	);

	categories.inherited_attributes = parent_attr_list.map((d) => {
		d.id = d.guid;
		d.label = d.name;
		return d;
	});

	var selected_options = {}
	if(categories.categories_attributes)
		categories.categories_attributes.map(ca => { 
			if(ca.category_id == categories.current.guid)
				selected_options[ca.attribute_id] = ca.name 
		})

	// form: (c, related_entity) => {
	return {
		name: "<span class='mr-1'>Modifiko</span> <b>" + categories.current.name + "</b>",
		submit: {
			label: 'Modifiko', 
				action: 'submitCategory'
		},
		delete: {
			guid: categories.current.guid,
			model: 'Category',
			api: 'category/delete/' + related_entity
		},
		fields: [
			[
				{ 
					value: categories.current.code, 
					name: 'code', 
					label: 'Kodi', 
					type: 'text-field', 
					unique: {
						model: 'category', 
						field: 'code',
						where: [
							{
								column: 'related_entity',
								condition: '=',
								value: related_entity 
							},
							{
								column: 'guid',
								condition: '!=',
								value: categories.current.guid 
							}
						]
					},  
					left_icon: 'fa-solid fa-hashtag', 
					style: "min-width: 45%",
					required: true,
				},
				{
					value: categories.current.name, 
					name: 'name', 
					label: 'Emri', 
					type: 'text-field', 
					style: "min-width: 45%",
					required: true,
					required_text: 'Kjo fushe eshte e detyrueshme'
				},
				{
					value: categories.current.active, 
					name: 'active', 
					label: 'Aktiv', 
					type: 'checkbox-field', 
					style: "min-width: 2%",
					unique: true, 
				},
			],
			{ 
				value: categories.current.description, 
				name: 'description', 
				label: 'Pershkrim', 
				type: 'textarea-field',
			},
			[
				{
					name: 'categories_attributes', 
					label: 'Atributet', 
					type: 'tree-select', 
					style: 'width: 100%',
					options: categories.attributes.filter(a => {
						var found = false
						categories.inherited_attributes.map(aa => {
							if(aa.attribute_id == a.id) found = true
						})
						return !found
					}), 
					selected_options: selected_options,
					inherited_selected_options: categories.inherited_attributes,
					mode: 'multiple',
					required: false
				},
			]
		]
	};
}
