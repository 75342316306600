import { useCategoryStore } from "@/stores/category";

import helpers from "@/helpers.js";

export default async function editWarehouseCategory() {

	let categories = useCategoryStore()
	var related_entity = 'warehouses'

	// create sort list 
	var sort_options = []
	categories.flat_list.map(f  => {
		if(f.parent_id == categories.current.parent_id){
			sort_options.push({
				id: f.sort_nr,
				label: f.sort_nr
			})
		}
	})
  
	// Transfer category
	var is_transfer = false

	var warehouse_categories = [...categories?.flat_list.filter(a => { 
	return a.guid != categories.current.guid
	})]
	var details_types = helpers.toTree(warehouse_categories, "id")
  // sort sort_options by label 
  sort_options.sort((a, b) => { 
    return a.label - b.label
  })

	var transfer_category = []
  if(categories.current?.total >= 0 ){
		transfer_category = [
			{
				value: is_transfer,
				name: "is_transfer",
				label: "Transfero ne kategorine",
				style: "min-width: 10%",
				type: "checkbox-field",
			},
			{
				name: "transfer_parent_id",
				label: "Kategoria",
				type: "tree-select",
				style: "min-width: 90%",
				options: details_types,
				mode: "single",
				select_root: true,
				selected_options: [],
				vif: [{ field: "is_transfer", value_not: 0 }],
			},
		]
	}

	// form: (c, related_entity) => {
	return {
		name: "<span class='mr-1'>Modifiko</span> <b>" + categories.current.name + "</b>",
		submit: {
			label: 'Modifiko', 
				action: 'submitCategory'
		},
		delete: {
			guid: categories.current.guid,
			model: 'Category',
			api: 'category/delete/' + related_entity
		},
		fields: [
			[
				{ 
					value: categories.current.code, 
					name: 'code', 
					label: 'Kodi', 
					type: 'text-field', 
					unique: {
						model: 'category', 
						field: 'code',
						where: [
							{
								column: 'related_entity',
								condition: '=',
								value: related_entity 
							},
							{
								column: 'guid',
								condition: '!=',
								value: categories.current.guid 
							}
						]
					},  
					left_icon: 'fa-solid fa-hashtag', 
					style: "min-width: 45%",
					required: true,
				},
				{
					value: categories.current.name, 
					name: 'name', 
					label: 'Emri', 
					type: 'text-field', 
					style: "flex: 1",
					required: true,
					required_text: 'Kjo fushe eshte e detyrueshme'
				},
				{
					value: categories.current.active, 
					name: 'active', 
					label: 'Aktiv', 
					type: 'checkbox-field', 
					style: "min-width: 80px",
					unique: true, 
				},
				{
					value: categories.current.sort_nr, 
					name: 'sort_nr', 
					label: 'Renditja', 
					type: 'select-field', 
					options: sort_options,
					style: "",
				},
			],
			{ 
				value: categories.current.description, 
				name: 'description', 
				label: 'Pershkrim', 
				type: 'textarea-field',
			},

			[...transfer_category]
		]
	};
}
