import { useCategoryStore } from "@/stores/category";
import CategoriesServices from "@/services/Category";
import ConfigurationService from "@/services/Configuration.js"

export default async function editCustomerCategory(parent_category) {

	let categories = useCategoryStore()
	var related_entity = 'customers'

	let attr_list = await CategoriesServices.getAttributesList(
		-1,
		related_entity
	);
	categories.attributes = attr_list.map((d) => {
		d.id = d.guid;
		d.label = d.name;
		return d;
	});
	var parent_attr_list = await CategoriesServices.getParentAttributeList(
		parent_category,
		related_entity,
		0
	);
	categories.inherited_attributes = parent_attr_list.map((d) => {
		d.id = d.guid;
		d.label = d.name;
		return d;
	});


  categories.categories_attributes = await CategoriesServices.getParentAttributeList(
		parent_category,
		related_entity,
		1
	);
	var selected_options = {}
	if(categories.categories_attributes)
	categories.categories_attributes.map(ca => { 
		// if(ca.category_id == categories.current.guid)
    // check is not already in the inherited list 
    var found = false
    categories.inherited_attributes.map(ia => { 
      if(ia.attribute_id == ca.attribute_id) found = true 
    })
    if(!found)
			selected_options[ca.attribute_id] = ca.attribute_name 
	})

	// BLOCK: ####################################################     CATEGORY PAYMENT TERMS 
  // LOGIC: Get all payment terms 
	var payments_data = await ConfigurationService.getPaymentsConfiguration()
  var payment_terms = payments_data.payment_terms.map(a => {
		a.id = a.guid
		a.label = a.name
		return a
	})
  
  // LOGIC: Get all parent payment terms included the current category
	var parent_terms_list = await CategoriesServices.getParentPaymentTerms(parent_category, 1);
  
  // LOGIC: Get all parent payment terms innherited from the parent category
  // excluding the current category
	categories.payment_inherited_attributes = [...parent_terms_list.map((d) => {
		d.id = d.guid;
		d.label = d.name;
		return d;
	})].filter(pt => pt.guid != parent_category);

  // LOGIC: Get all parent payment terms included the current category
  // and set the select options variable
  var list = parent_terms_list.map((d) => {
		d.id = d.guid;
		d.label = d.name;
		return d;
	}).filter(pt => pt.guid == parent_category);
  
  categories.selected_payment_terms = {}
  if(list.length > 0){
    list.map(pt => {
      categories.selected_payment_terms[pt.payment_term_id] = pt.attribute_name
    })
  }

  // LOGIC: Exclude from the available options the payment terms already included
  // as inherited from the parent category
  categories.payment_terms = payment_terms.filter(pt => {
    var fount = false 
    categories.payment_inherited_attributes.map(pia => {
      if(pia.payment_term_id == pt.guid && pia.guid != parent_category) fount = true
    })
    return !fount
  });


	return {
		name: "<span class='mr-1'>Modifiko</span> <b>" + categories.current.name + "</b>",
		submit: {
			label: 'Modifiko', 
				action: 'submitCategory'
		},
		delete: {
			guid: categories.current.guid,
			model: 'Category',
			api: 'category/delete/' + related_entity
		},
		fields: [
			[
				{ 
					value: categories.current.code, 
					name: 'code', 
					label: 'Kodi', 
					type: 'text-field', 
					unique: {
						model: 'category', 
						field: 'code',
						where: [
							{
								column: 'related_entity',
								condition: '=',
								value: related_entity 
							},
							{
								column: 'guid',
								condition: '!=',
								value: categories.current.guid 
							}
						]
					},  
					left_icon: 'fa-solid fa-hashtag', 
					style: "min-width: 45%",
					required: true,
				},
				{
					value: categories.current.name, 
					name: 'name', 
					label: 'Emri', 
					type: 'text-field', 
					style: 'min-width: 45%',
					required: true,
					required_text: 'Kjo fushe eshte e detyrueshme'
				},
				{
					value: categories.current.active, 
					name: 'active', 
					label: 'Aktiv', 
					type: 'checkbox-field', 
					style: "min-width: 2%",
					unique: true, 
				},
			],
			{ 
				value: categories.current.description, 
				name: 'description', 
				label: 'Pershkrim', 
				type: 'textarea-field',
			},
			{
				name: 'categories_attributes', 
				label: 'Atributet', 
				type: 'tree-select', 
				style: 'width: 100%',
				options: categories.attributes.filter(a => {
					var found = false
					categories.inherited_attributes.map(aa => {
						if(aa.attribute_id == a.id) found = true
					})
					return !found
				}), 
				selected_options: selected_options,
				inherited_selected_options: categories.inherited_attributes,
				mode: 'multiple',
				required: false
			},
			{
				name: 'payment_terms', 
				label: 'Termat e pageses', 
				type: 'tree-select', 
				style: 'width: 100%',
				options: categories.payment_terms, 
				selected_options: categories.selected_payment_terms,
				inherited_selected_options: categories.payment_inherited_attributes,

				mode: 'multiple',
				required: false
			},
		]
	};
}
