import { useCategoryStore } from "@/stores/category";
// import helpers from "@/helpers.js";
import CategoriesServices from "@/services/Category";

export default async function editAccessoryCategory(parent_category) {
	let categories = useCategoryStore()
	var related_entity = 'accessories'

	let attr_list = await CategoriesServices.getAttributesList(
		-1,
		'products'
	);
	categories.attributes = attr_list.map((d) => {
		d.id = d.guid;
		d.label = d.name;
		return d;
	});
	var parent_attr_list = await CategoriesServices.getParentAttributeList(
		parent_category,
		related_entity,
		0
	);
	categories.inherited_attributes = parent_attr_list.map((d) => {
		d.id = d.guid;
		d.label = d.name;
		return d;
	});
	var selected_options = {}
	if(categories.categories_attributes)
		categories.categories_attributes.map(ca => { 
			if(ca.category_id == categories.current.guid)
				selected_options[ca.attribute_id] = ca.name 
		})

	// Accessories categories
	// let accessories_list = await CategoriesServices.getCategories("accessories")
	// // Assign the accessories to categories
	// categories.accessories = helpers.toTree(accessories_list, "id")

	// var parent_acc_list = await CategoriesServices.getParentAccessoryList(
	// 	parent_category,
	// 	related_entity,
	// 	0
	// );

	
	// Get services categories
	let service_types = await CategoriesServices.getServicesCategories()
	categories.services = service_types.map((d) => {
		d.id = d.guid;
		d.label = d.name;
		return d;
	});

	var selected_options_service_type = {}
	if(categories.categories_service_types)
		categories.categories_service_types.map(ca => { 
			if(ca.category_id == categories.current.guid)
				selected_options_service_type[ca.service_type_id] = ca.name 
		})

	var parent_service_type_list = await CategoriesServices.getParentServiceTypeList(
		parent_category,
		0
	);
	categories.inherited_service_types = parent_service_type_list.map((d) => {
		d.id = d.guid;
		d.label = d.name;
		return d;
	});

	// create sort list 
	var sort_options = []
	// var index = 0 
	categories.flat_list.map(f => {
		if(f.parent_id == categories.current.parent_id){
			sort_options.push({
				id: f.sort_nr,
				label: f.sort_nr
			})
			// index += 1
		}
	})

	// form: (a, related_entity) => {
	return {
		name: "<span class='mr-1'>Modifiko</span> <b>" + categories.current.name + "</b>",
		submit: {
			label: 'Modifiko', 
			action: 'submitAccessory'
		},
		delete: {
			guid: categories.current.guid,
			model: 'Category',
			api: 'category/delete/' + related_entity
		},
		fields: [
			[
				{ 
					value: categories.current.code, 
					name: 'code', 
					label: 'Kodi', 
					type: 'text-field', 
					unique: {
						model: 'accessory', 
						field: 'code',
						where: [
							{
								column: 'related_entity',
								condition: '=',
								value: related_entity 
							},
							{
								column: 'guid',
								condition: '!=',
								value: categories.current.guid 
							}
						]
					},  
					left_icon: 'fa-solid fa-hashtag', 
					style: "min-width: 45%",
					required: true,
				},
				{
					value: categories.current.name, 
					name: 'name', 
					label: 'Emri', 
					type: 'text-field', 
					style: "min-width: 45%",
					required: true,
					required_text: 'Kjo fushe eshte e detyrueshme'
				},
				{
					value: categories.current.active, 
					name: 'active', 
					label: 'Aktiv', 
					type: 'checkbox-field', 
					style: "min-width: 2%",
					unique: true, 
				},
				{
					value: categories.current.sort_nr, 
					name: 'sort_nr', 
					label: 'Renditja', 
					type: 'select-field', 
					options: sort_options,
					style: "",
				},
			],
			{ 
				value: categories.current.description, 
				name: 'description', 
				label: 'Pershkrim', 
				type: 'textarea-field',
			},
			{
				name: 'categories_attributes', 
				label: 'Atributet', 
				type: 'tree-select', 
				style: 'width: 100%',
				options: categories.attributes.filter(a => {
					var found = false
					categories.inherited_attributes.map(aa => {
						if(aa.attribute_id == a.id) found = true
					})
					return !found
				}), 
				selected_options: selected_options,
				inherited_selected_options: categories.inherited_attributes,
				mode: 'multiple',
				required: false
			},
			{
				name: 'categories_services', 
				label: 'Tipet e sherbimeve', 
				type: 'tree-select', 
				style: 'width: 100%',
				// options: categories.services, 
				options: categories.services.filter(a => {
					var found = false
					categories.inherited_service_types.map(aa => {
						if(aa.service_type_id == a.id) found = true
					})
					return !found
				}), 
				selected_options: selected_options_service_type,
				inherited_selected_options: categories.inherited_service_types,
				mode: 'multiple',
				required: false
			},


			// {
			// 	name: "categories_accessories",
			// 	label: "Aksesoret",
			// 	type: "tree-select",
			// 	style: "min-width: 30%",
			// 	// options: accessories,
			// 	// selected_options: [],

			// 	options: categories.accessories.filter(a => {
			// 		var found = false
			// 		categories.inherited_accessories.map(aa => {
			// 			if(aa.accessory_id == a.id) found = true
			// 		})
			// 		return !found
			// 	}), 
			// 	selected_options: selected_options_accessories,
			// 	inherited_selected_options: categories.inherited_accessories,

			// 	mode: 'multiple',
				
			// },
		]
	};
}
