import Api from "@/services/Api";
import helpers from "@/helpers.js";

export default {
	async getAccessories(entity){
		var accessories = [];
		await Api(true)
		.post("accessory/list/" + entity, {})
		.then((response) => {
			accessories = response.data.map((e) => {
				e.id = e.guid;
				e.label = e.name;
				e.collapsed = true;
				return e;
			}),
			"id"
		});
		return accessories;
	},
	async getTreeAccessories(flat_list, relation) {
		return helpers.toTree(flat_list, relation)
	},

	async getAccessoryProcess(accessory, mode, related_entity) {
		await Api(true).post(
			mode == "add"
			? "accessory/create/" + related_entity
			: "accessory/edit/" + related_entity,
			accessory
		)
		.then(r => {
			accessory = r.data
		})
		return accessory
	}
}
