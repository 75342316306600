<template>
  <div>
    <input type="text" placeholder="Kerko..." class="input is-small" style="z-index: 2" v-if="items.length > 3 || first_node == true"
      v-model="search">
    <ul class="tree">
      <!-- li is draggable -->
      <li v-for="i of items.filter(item => JSON.stringify(item).toLowerCase().includes(search.toLowerCase()))" 
        :key="i.guid">
        <div class="list-itema flex" >
          <a href="" @click.prevent="collapse(i)">
            <i class="fa" :class="{ 'fa-square-plus': i.collapsed, 'fa-square-minus': !i.collapsed }"
              style="z-index: 4; position: relative" v-if="Array.isArray(i.children) && i.children.length > 0"></i>
          </a>
          <span 
            v-if="categories" class="tooltip pl-2 text-xs flex items-center" :class="{ 'bg-green-50 border': i.guid == categories.current.guid }">
            <!-- draggable="true" @dragstart="drag($event, i)" @dragover="allowDrop($event)" @drop="drop($event, i)" -->
            <span class="uppercase flex"
              :class="[!Array.isArray(i.children) || i.children.length == 0 ? 'items-end': 'items-center']"
              >
              {{ i.name }} 
              <small v-if="Array.isArray(i.children) && i.children.length > 0" title="Nenkategori"
                  class="ml-1 text-slate-500 font-normal bg-green-100 w-5 flex items-center justify-center">
                {{i.children?.length}}
              </small>
              <small v-if="i.total > 0" :title="module.title + ' te lidhura'"
                  class="ml-1 text-slate-500 font-normal bg-blue-100 w-5 flex items-center justify-center">
                {{i.total}}
              </small>
            </span>
            <img style="display: inline" class="ml-3 mb-3" width="70" height="70" v-if="i.image"
              :src="i.image" alt="">
            <span class="tooltiptext py-1 ml-3 pl-2 flex items-center justify-between">
              <a class="is-size-8 has-text-grey mr-4" @click.prevent="setCurrent(i, 'add')"
                v-if="(levels == -1
                  || (levels > 1 && (node_has_children && node_has_children.prop && node_has_children.value && i[node_has_children.prop] == node_has_children.value)))">
                <i class="fa fa-plus hover:has-text-success"></i>
              </a>

              <a class="is-size-8 has-text-grey mr-2" @click.prevent="setCurrent(i, 'edit')">
                <i class="fa fa-pencil"></i>
              </a>
            </span>

          </span>
        </div>

        <Transition name="slide-fade">
          <Tree :items="i.children" :flat="false" :levels="levels" :module="module" :first_node="false"
            v-if="Array.isArray(i.children) && i.children.length > 0 && !i.collapsed" />
        </Transition>
      </li>
    </ul>
  </div>
</template>

<script>
import { storeToRefs } from 'pinia'
import { useCategoryStore } from '@/stores/category'
import { useAccessoryStore } from '@/stores/accessory'
import CategoriesServices from "@/services/Category";
import AccessoryServices from "@/services/Accessory";
export default {
  name: "Tree",
  props: {
    items : Array,
    flat  : Boolean,
    levels: {
      type     : Number,
      required : false,
      default  : -1
    },
    node_has_children: {
      type     : Object,
      required : false,
      default: () => {
        return null
      }
    },
    module: {
      type     : Object,
      required : false,
      default  : null
    },
    first_node: {
      type: Boolean, 
      required: false,
      default: false
    }
  },
  data() {
    return {
      categories  : storeToRefs(useCategoryStore()),
      accessories : storeToRefs(useAccessoryStore()),
      collapsed   : false,
      search      : ""
    };
  },
  methods: {
    drag(ev, i) {
      ev.dataTransfer.setData("text", JSON.stringify(i));
    },
    allowDrop(ev) {
      ev.preventDefault();
    },
    async drop(ev, i) {
      ev.preventDefault();
      var data = ev.dataTransfer.getData("text");
      data = JSON.parse(data)
      if (data.guid != i.guid) {
        console.log(data, i)
      }
    },
    async collapse(i) {
      this.categories.flat_list = [...this.categories.flat_list.map(e => {
        if (e.guid == i.guid)
          e.collapsed = !e.collapsed
        return e
      })]
      this.categories.list = await CategoriesServices.getTreeCategories(this.categories.flat_list, "id")

      this.accessories.flat_list = [...this.accessories.flat_list.map(aa => {
        if (aa.guid == i.guid)
          aa.collapsed = !aa.collapsed
        return aa
      })]
      this.accessories.list = await AccessoryServices.getTreeAccessories(this.accessories.flat_list, "id")
    },
    setCurrent(c, mode) {
      this.categories.mode = mode
      this.accessories.mode = mode
      this.categories.current = { ...c }
      this.accessories.current = { ...c }
    },
    // creates tree struct
    list_to_tree(list) {
      var map = {}, node, roots = [], i;
      for (i = 0; i < list.length; i += 1) {
        map[list[i].guid] = i; // initialize the map
        list[i].children = []; // initialize the children
      }
      for (i = 0; i < list.length; i += 1) {
        node = list[i];
        if (node.parent_id !== null) {
          // if you have dangling branches check that map[node.parentId] exists
          if (map[node.parent_id])
            list[map[node.parent_id]].children.push(node);
        } else {
          roots.push(node);
        }
      }
      return roots;
    },
    toTree(dataset) {
      const hashTable = Object.create(null);
      dataset.forEach(aData => hashTable[aData.guid] = { ...aData, children: [] });
      const dataTree = [];
      dataset.forEach(aData => {
        if (aData.parent_id) hashTable[aData.parent_id].children.push(hashTable[aData.guid])
        else dataTree.push(hashTable[aData.guid])
      });
      return dataTree;
    },
  },
  computed: {
    list_items() {
      if (this.items) {
        if (this.flat) {
          return this.toTree(this.items, null)
        }
        else
          return this.items
      }
      else return []
    }
  }
};
</script>
<style scoped>
.tree,
.tree ul {
  margin            : 0 0 0 0em;
  /* indentation */
  padding           : 0;
  list-style        : none;
  position          : relative;
}

.list-itema {
  display         : flex;
  justify-content : space-between;
  border-bottom   : 1px dashed #eee;
}

.tree ul {
  margin-left : .5em
}

/* (indentation/2) */

.tree:before,
.tree ul:before {
  content     : "";
  display     : block;
  width       : 0;
  position    : absolute;
  top         : -17px;
  bottom      : 0;
  left        : 0;
  border-left : 1px solid;
}

.tree li {
  margin                                  : 0;
  padding                                 : 0 0 0 15px;
  /* indentation + .5em */
  line-height                             : 2em;
  /* default list item's `line-height` */
  font-weight                             : bold;
  position                                : relative;
}

.tree li>li:hover {
  background : #f8f8f8;
}

.tree li:before {
  content                     : "";
  display                     : block;
  width                       : 15px;
  /* same with indentation */
  height                      : 0;
  border-top                  : 1px solid;
  margin-top                  : -1px;
  /* border top width */
  position                    : absolute;
  top                         : 1em;
  /* (line-height/2) */
  left                        : 0;
}

.tree li:last-child:before {
  background                      : white;
  /* same with body background */
  height                          : auto;
  top                             : 1em;
  /* (line-height/2) */
  bottom                          : 0;
}

.tree .is-size-8 {
  font-size : 0.7rem !important;
}

.tooltip {
  width           : 100%;
  display         : flex;
  justify-content : space-between;
}

.tooltip:hover {
  background-color : #f5f5f5;
}

.tooltip .tooltiptext {
  opacity          : 0;
  background-color : rgba(255, 255, 255, 0.8);
  border-radius    : 4px;
  padding          : 0;

  border           : 1px solid #e0e0e0;
}

.tooltip:hover .tooltiptext {
  opacity : 1;
}

.slide-enter-active {
  -moz-transition-duration           : 0.3s;
  -webkit-transition-duration        : 0.3s;
  -o-transition-duration             : 0.3s;
  transition-duration                : 0.3s;
  -moz-transition-timing-function    : ease-in;
  -webkit-transition-timing-function : ease-in;
  -o-transition-timing-function      : ease-in;
  transition-timing-function         : ease-in;
}

.slide-leave-active {
  -moz-transition-duration           : 0.3s;
  -webkit-transition-duration        : 0.3s;
  -o-transition-duration             : 0.3s;
  transition-duration                : 0.3s;
  -moz-transition-timing-function    : cubic-bezier(0, 1, 0.5, 1);
  -webkit-transition-timing-function : cubic-bezier(0, 1, 0.5, 1);
  -o-transition-timing-function      : cubic-bezier(0, 1, 0.5, 1);
  transition-timing-function         : cubic-bezier(0, 1, 0.5, 1);
}

.slide-enter-to,
.slide-leave {
  max-height : 100px;
  overflow   : hidden;
}

.slide-enter,
.slide-leave-to {
  overflow   : hidden;
  max-height : 0;
}


.slide-fade-enter-active {
  transition : all 0.2s ease-out;
}

.slide-fade-leave-active {
  transition : all 0.2s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform : translateY(-20px);
  opacity   : 0;
}

/* (A) LIST TO MENU */
.tree-menu,
.section ul {
  list-style : none;
  padding    : 0;
  margin     : 0;
}

.tree-menu {
  background : #fbfbfb;
  border     : 1px solid #d2d2d2;
}

.tree-menu li {
  border-bottom : 1px solid #d2d2d2;
  padding       : 15px 10px;
}

.tree-menu li:last-child {
  border: 0;
}

/* (B) SUB-SECTIONS */
/* (B1) TOGGLE SHOW/HIDE */
.tree-menu .section ul {
  display: none;
}

.tree-menu .section input:checked~ul {
  display: block;
}

/* (B2) HIDE CHECKBOX */
.tree-menu .section input[type=checkbox] {
  display : none;
}

/* (B3) ADD EXPAND/COLLAPSE ICON  */
.tree-menu .section {
  position     : relative;
  padding-left : 35px !important;
}

.tree-menu .section label:after {
  content                 : "\0002B";
  position                : absolute;
  top                     : 0;
  left                    : 0;
  padding                 : 10px;
  text-align              : center;
  font-size               : 30px;
  color                   : #f00;
  transition              : all 0.5s;
}

.tree-menu .section input:checked~label:after {
  color     : #23c37a;
  transform : rotate(45deg);
}

/* (B4) SUB-SECTION ITEMS */
.tree-menu .section ul {
  margin-top : 10px;
}

.tree-menu .section ul li {
  color: #d43d3d;
}
</style>
